module.exports = [{
      plugin: require('/Users/tom/dev/mwf/getally/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/tom/dev/mwf/getally/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-52SQT9D","includeInDevelopment":false},
    },{
      plugin: require('/Users/tom/dev/mwf/getally/node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"ifz0vanc","include_in_development":true},
    },{
      plugin: require('/Users/tom/dev/mwf/getally/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":420,"linkImagesToOriginal":false},
    },{
      plugin: require('/Users/tom/dev/mwf/getally/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
